header {
    width: 100%;
    height: 6.5vh;
    text-align: center;
    background-color: #ffffff;
}
.header-title  {
    font-size: 12px;
    padding-top: 14px;
    color: black;
}
.back-button {
    padding: 12px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.back-button img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}
.airplane-icon {
    padding: 12px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.airplane-icon img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}