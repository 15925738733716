html,
body,
#root {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

main {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 6.5vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.desktop-fallback {
  width: 100%;
  height: 100%;
  margin-top: 30%;
  text-align: center;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  cursor: none;
}

input {
  outline: none
}

textarea:focus, input:focus, button:focus{
  outline: none;
}
a:focus, a:link, a:hover, a:active {
  text-decoration: none;
  color: black;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

button {
  background: transparent;
  border: transparent;
}